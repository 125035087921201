<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Local settings (SCORE)
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Local settings
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
<!--                  <v-btn-->
<!--                      @click="createScore()"-->
<!--                      class="btn btn-primary font-weight-bolder font-size-sm"-->
<!--                      style="color: #fff;">-->
<!--                    <i class="fa fa-plus"></i>-->
<!--                    Add SCORE-->
<!--                  </v-btn>-->
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="SCORE"
                      v-model="search.name" clearable
                      outlined  v-on:keyup.enter="searchScores"
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Purchase prefix"
                      v-model="search.purchase_prefix" clearable
                      outlined  v-on:keyup.enter="searchScores"
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Enrolment year"
                      v-model="search.current_enrolment_year_id"
                      :items="enrollmentYears" clearable
                      item-text="year"
                      item-value="id" :menu-props="{ bottom: true, offsetY: true }"
                      outlined   v-on:keyup.enter="searchScores"
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="5">
                  <v-btn   :loading="isLoading" @click="searchScores()" class="btn btn-primary w-35 float-right" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')">SCORE</th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Prefix</th>
                  <th class="px-3">Currency</th>
                  <th class="px-3">Tax</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="score in sortedItems" :key="score.id">
                    <td class="px-2">
                      <a @click="editScore(score.id)" class="">
                        {{ score.name }}
                      </a>
                    </td>
                    <td class="px-2">
                      <a class="text-info mb-1" :href="'mailto:'+score.contact_person_email">
                        {{score.contact_person_email ? score.contact_person_email : 'NA' }}
                      </a>
                    </td>
                    <td class="px-2">
                      {{ score.purchase_prefix }}
                    </td>
                    <td class="px-2">
                      {{ score.currency_symbol }}
                    </td>
                    <td class="px-2">
                      {{ score.tax_setting }}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editScore(score.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="scoreSettingText(score.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-a"></i>

                                </span>
                                <span class="navi-text">Localisation</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="examChecklist(score.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-check"></i>
                                </span>
                                <span class="navi-text">Exam checklist</span>
                              </a>
                            </b-dropdown-text>
<!--                            <b-dropdown-text tag="div" class="navi-item">-->
<!--                              <a class="navi-link" @click.prevent="deleteScore(score.id)">-->
<!--                                 <span class="navi-icon">-->
<!--                                   <i class="fas fa-trash-alt"></i>-->
<!--                                </span>-->
<!--                                <span class="navi-text">Delete</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="scores.length > 0"
                  class="pull-right mt-7"
                  @input="getAllScores" :disabled="isLoading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </v-app>
</template>

<script>
import ScoreService from "@/services/score/score/ScoreService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import CreateAndUpdate from "@/view/pages/view/score/exam-checklist/CreateAndUpdate.vue";

const score = new ScoreService();
const enrolment = new EnrolmentYearService();


export default {
  name: "Index",
  components: {CreateAndUpdate},
  data(){
    return{
      scores: [],
      enrollmentYears: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      isLoading:false,
      search:{
        name:'',
        purchase_prefix:'',
        enrolment_year_id:'',
      },
    }
  },
  mounted() {
    this.getAllScores();
    this.getAllEnrolmentYears();
  },
  computed: {
    sortedItems() {
      const list = this.scores.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createScore() {
      this.$router.push({
        name:"score-create"
      });
    },
    editScore(scoreId) {
      this.$router.push({
        name:'score-edit',
        params:{ scoreId: scoreId}
      });
    },
    examChecklist(scoreId) {
      this.$router.push({
        name:'score-exam-checklist',
        params:{ scoreId: scoreId}
      });
    },
    getAllScores(){
      this.isLoading = true;
      score
          .paginate(this.search, this.page)
          .then(response => {
            this.scores = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
          });
    },
    getAllEnrolmentYears(){
      enrolment
          .all()
          .then(response => {
            this.enrollmentYears = response.data;
          })
          .catch((err) => {
          });
    },
    searchScores(){
      this.getAllScores();
    },
    deleteScore(scoreId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            score
                .delete(scoreId)
                .then((response) => {
                  this.getAllScores();
                  this.$snotify.success("Score deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    scoreSettingText(scoreId){
      this.$router.push({
        name:'score-setting-text',
        params:{ scoreId: scoreId}
      })
    }
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>